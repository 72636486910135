<template>
  <!-- Informations générales communes à toutes les pages -->
  <div class="tabs-material-subtitle no-padding-bottom">
    <Container>
      <h2>Variables calculées</h2>
    </Container>
  </div>
  <div class="variables-etudiees-subview">
    <Section>
      <List
        :api="list.api"
        :header="list.headers"
        :items="list.col"
        :item-key="list.key"
        disableCheckbox
        disableActions
      >
        <template v-slot:calcul="{ item }">
          {{ item.calcul.designation }}
        </template>
      </List>
    </Section>
  </div>
</template>

<script>
import List from '@/components/list/List.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'VariablesCalculatedSubview',
  components: {
    List,
    Section,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      list: {
        api: {
          endpoint: null,
          params: {
            sort: null,
            page: null,
            limit: 0,
            filters: null,
          },
        },
        headers: [
          { label: 'Designation' },
        ],
        col: ['calcul'],
        key: 'id',
      },
    }
  },
  created() {
    this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`).then((res) => {
      const currentNotation = res.data[res.data.length - 1]
      this.list.api.endpoint = `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${currentNotation.id}/variable_calculee`
    })
  },
}
</script>

<style lang="scss" scoped>

</style>
